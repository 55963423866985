var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-content"},[_c('div',{staticClass:"container-fluid"},[_c('Breadcrumb',{attrs:{"pgTitle":_vm.$t('nav.emergencyConsultations'),"pgIcon":'bx bx-flag',"refs":_vm.refs,"addNew":_vm.addNew}}),_c('DataTable',{attrs:{"refs":_vm.refs,"colspan":9,"hasSearchFilter":true,"hasDateFilter":true,"hasStatusApproved":true,"hasStatusFilter":true,"hasLecturerFilter":true,"hasHashId":true,"hasMultiSelect":false,"hasBulkAction":false,"HasExport":true,"hasShowEntries":true,"lblSearch":_vm.$t('app.search_by_name'),"fields":{
                'id': 'id',
                'username': 'username',
                'price': 'price',
                'question': 'question',
                'paid': 'paid',
                'status': 'status'
            },"hasUser":true,"image":_vm.$t('view.user'),"image_style":'width: 40px;height: 40px;border-radius: 50%',"price":_vm.$t('app.price'),"hasQuestion":true,"hasPrice":true,"hasDate":true,"hasPaid":true,"hasStatus":true,"hasNoEdit":true,"hasAccept":true,"hasDecline":true,"hasApproved":true,"hasActions":true,"hasNoDelete":true,"actionable":true,"sorDataTable":true},on:{"hasAddNewPermission":_vm.handlePermissoinChange}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }